import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDe3jLb9DYoowM0ILb-gztfwfziydxdz24",
  authDomain: "whirlpool-king-lp.firebaseapp.com",
  projectId: "whirlpool-king-lp",
  storageBucket: "whirlpool-king-lp.appspot.com",
  messagingSenderId: "587723210446",
  appId: "1:587723210446:web:38039fa107bf3bd8491a9f",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export default db;
