import React, { useState } from 'react'

const Modal = ({ url, show, onStatusChange }) => {

    const removeClass = () => {


    }


    return (
        <div class={"youtubeModal" + (show ? "" : " hidden")}>

            <div className="inside">
                <iframe width="100%" height="250" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div onClick={() => { onStatusChange() }} className="close">SCHLIEßEN</div>

            </div>



        </div>
    )
}

export default Modal;
