import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Table from "./partials/table";
import TableEmbed from "./partials/tableEmbed";
import Testbericht from "./pages/Testbericht";
import UrbanAurora from "./pages/UrbanAurora";

ReactDOM.render(

  
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/tabelle/">
          <TableEmbed />
        </Route>

        <Route path="/tabelle-2/">
          <TableEmbed />
        </Route>

        <Route path="/tabelle-3/">
          <TableEmbed />
        </Route>

        <Route path="/comfort-bergen/">
          <Testbericht />
        </Route>

        <Route path="/urban-aurora/">
          <UrbanAurora />
        </Route>

        <Route path="/">
          <App />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
