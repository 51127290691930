import React, { useState } from "react";
import "./header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div class="header">
        <div className="left">
          <img
            src="/whirlpool-king-logo.png"
            alt=""
          />
        </div>
        <div className="right" onClick={() => { setMenuOpen(!menuOpen) }}>

          {
            !menuOpen ? <i class="fas fa-bars"></i> :
              <i class="fas fa-chevron-up"></i>
          }
        </div>
      </div>
      <div className={menuOpen ? "menu open" : "menu"}>
        <ul>
          <li><a target="_blank" href="https://www.whirlpool-king.de/whirlpool-aufblasbar-test/"><i className="fa fa-arrow-left"></i> Zurück zum alten Design der Website</a></li>
          <li><a href="//whirlpoolking.de/">Aufblasbarer Whirlpool</a></li>
          <li><a href="//www.whirlpool-king.de/whirlpool-test/" target="_blank">Outdoor Whirlpool</a></li>
          <li><a href="//www.whirlpool-king.de/gartenpool-test/" target="_blank">Gartenpool</a></li>
        </ul>

      </div>
    </>
  );
};

export default Header;
