import teaser from "./teaser.png";
import "./App.css";
import Header from "./partials/header";
import Table from "./partials/table";
import Modal from "./partials/modal";
import LazyLoad from "react-lazyload";
import db from "./firebase";
import ProductFilter from "./partials/ProductFilter";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


function App() {
  const [counter, setCounter] = useState(77423);
  const [updateDate, setUpdateDate] = useState("03.04.2021");

  useEffect(() => {
    db.collection("config").onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log(data[0]);
      setCounter(data[0].count);
      setUpdateDate(data[0].updateDate);
    });
  }, []);

  return (
    <div className="app">
      {/*<Modal url="https://www.youtube.com/embed/EangpWonmDM" />*/}
      <Header></Header>

      <img
        style={{ filter: "contrast(0.95)" }}
        width="100%"
        src="/bekannt_aus_banner.jpg"
        alt=""
        className="presse"
      />
      <span class="presse-before">
        WHIRLPOOL KING IST BEKANNT AUS DIESEN ONLINE-PORTALEN
      </span>

      <div className="intro">
        <LazyLoad>
          <img src={teaser} width="100%" />
        </LazyLoad>
        <h1>Aufblasbarer Whirlpool Test & Vergleich 2021 🏆</h1>
        <p>
          In diesem Vergleich zeigen wir Ihnen die 10 besten aufblasbaren
          Whirlpools 2021. Wir aktualisieren alle Preise mehrmals täglich und
          leiten Sie stets zum aktuell günstigsten Anbgebot weiter. Insgesamt
          wurden 24 Produkte von 8 verschiedenen Herstellern analysiert und
          verglichen. <em>Zuletzt aktualisiert: {updateDate}</em>
        </p>
        <div className="visitors">
          <i class="fas fa-user-friends"></i> {counter.toLocaleString("fi-FI")}{" "}
          Nutzer beraten
        </div>

        <div className="warningBox" style={{ display: "none" }}>
          <p><strong><i className="fas fa-exclamation-triangle"></i> Achtung:</strong> Aufgrund der kommenen Hitzewelle könnte es bei einigen Herstellern vorübergehend bald zu ausverkauften
            Produkten kommen.</p>

          <img width="100%" src="https://i.gyazo.com/269164b690bf9ce2ea8f3df06ce37706.png" alt="" />

        </div>
      </div>

      <Table></Table>

      <div className="mainContent">
        <div className="inside">
          <LazyLoad once>
            <img
              src="/aufblasbare-whirlpools-groessen-36acd49e.jpg"
              width="100%"
              alt=""
            />
          </LazyLoad>
          <p style={{ padding: "7.5px 10px", backgroundColor: "#e6f5ff" }}>
            <strong>Für später merken:</strong> Speichern Sie diese Seite als
            Lesezeichen oder lassen Sie sie im Browser offen.
          </p>

          <Accordion>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Bester Whirlpool mit Wasserdüsen
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <img style={{ marginBottom: "-10px", marginTop: "5px" }} src="/Miweba_MSpa_Muse_Otium_M-OT061_aufblasbarer_whirlpool_test-768x768.jpg" width="120" />
                </p>
                <p>
                  <a target="_blank" href="https://t.adcell.com/p/click?promoId=146101&slotId=84151&subId=muse_otium_hauptseite&param0=https%3A%2F%2Fwww.trade-line-partner.com%2Fwhirlpools-aufblasbar%2Fmspa_whirlpool_otium_185x185x68cm.html">Miweba MSpa Muse Otium M-OT061</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Bester Whirlpool für 2 Personen
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a href="https://www.miweba.de/garten/outdoor-whirlpool/23591/miweba-mspa-2-personen-whirlpool-urban-nest-u-ne021-mit-rattan-tisch-m-one-ozon-uv-c-desinfektion?sPartner=2131844" target="_blank">Miweba MSpa Urban Nest U-NE021</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Bester Whirlpool für 4 Personen
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a href="/comfort-bergen/">Miweba MSpa Comfort Bergen C-BE041</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Bester Whirlpool mit Salzwassersystem
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a href="https://amzn.to/3hHqww0" target="_blank">Intex Whirlpool Pure SPA Octagon Bubble Jet</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Bester wintertauglicher Whirlpool
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a href="/urban-aurora/">Miweba MSpa Urban Aurora U-AU061</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Bester Whirlpool unter 500 Euro
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  <a href="https://www.amazon.de/dp/B07BHMBLXQ?tag=whirlpool-king-split-21&linkCode=ogi&th=1&psc=1" target="_blank">Bestway Lay-Z-Spa Miami AirJet</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <h2>Häufig Gestellte Fragen (FAQ)</h2>
          <p>
            Hier beantworten wir Ihnen die meistgestelltesten Fragen zu
            aufblasbaren Whirlpools, möglichen Kosten, Pflegeaufwand und vielem
            mehr.
          </p>

          <h3>1. Gibt es einen Testbericht von Stiftung Warentest?</h3>

          <p>
            Nein, derzeit gibt es keinen Testbericht zu aufblasbaren Whirlpools
            von Stiftung Warentest. Auch ÖKO-Test hat noch keinen Test oder
            Vergleich veröffentlicht.{" "}
          </p>

          <p>
            <strong>
              ⚠ Folgende Seiten empfehlen wir{" "}
              <span style={{ color: "red" }}>NICHT</span>
            </strong>
            :
            <ul>
              <li>Computerbild.de/vergleich/</li>
              <li>Bild.de/vergleich/</li>
              <li>RTL.de/vergleich/</li>
              <li>Gartentipps.com</li>
              <li>Vergleich.org</li>
            </ul>
          </p>

          <p>
            Diese "Tests" bzw. Vergleiche sind teilweise zusammengewürfelt und
            zeigen oft nur zu Amazon, anstatt den günstigsten Anbieter zu
            verlinken. Zudem werden "Testnoten" immer wieder unnachvollziehbar
            geändert und es gibt keine detaillierten Testberichte zu einzelnen
            Produkten. <i>(Unsere eigene Meinung)</i>
          </p>

          <p>
            <strong>Wie wir die Podukte vergleichen:</strong>
          </p>
          <p>
            Wir haben für die Kategorie "Aufblasbare Whirlpools" eine Formel
            entwickelt, mit der wir jedes Produkt einstufen und ranken können.
            Dabei vergeben wir für jede Funktion eine gewisse Punktanzahl. Für
            manche Funktionen gibt es mehr Punkte, für andere etwas weniger.
            Nach diesem Prozess dividieren wir die Anzahl der Luftdüsen durch 10
            und addieren auch diesen Wert. Danach rechnen wird den Preis / 100
            und ziehen diesen Wert von der bisherigen Punktzahl ab. Zuletzt wird
            die Punktzahl noch mit der Durchschnittsbewertung (1 bis 5)
            multipliziert. Das Endergebnis ist ein Richtwert, der sich optimal
            vergleichen lässt.
          </p>

          <p>
            Da im Jahr 2021 zahlreiche neue Funktionen hinzugekommen sind, haben
            wir auch dementsprechend die Formel neu angepasst.
          </p>

          <h3>2. Warum sind aufblasbare Whirlpools so beliebt?</h3>

          <p>
            Betrachtet man die Zahlen der letzten Jahren, so erkennt man
            eindeutig, dass sich die Nachfrage nach aufblasbaren Whirlpools
            jedes Jahr verdoppelt. Aufblasbare Whirlpools sind deshalb so
            beliebt, weil Sie im Vergleich zu herkömmlichen Whirlpools nur einen
            Bruchteil kosten, aber vom Wellnessfaktor dennoch sehr ähnlich sind.{" "}
          </p>

          <p>
            Zudem sind sie leicht und schnell auf-, sowie abgebaut und können
            somit ideal transportiert werden. Auch die Qualität des Materials
            ist sehr hoch, weshalb aufblasbare Whirlpools weitaus langlebiger
            sind, als man vielleicht annehmen möchte.
          </p>

          <h3>
            3. Warum ist MSpa derzeit Marktführer bei aufblasbaren Whirlpools?
          </h3>
          <p>
            Der Whirlpool Hersteller MSpa hat in den letzten Jahren enorm
            aufgeholt und ist derzeit dabei, Bestway und Intex vom Markt zu
            verdrängen. Mit der neuen 2021er Serie wurden die Modelle nocheinmal
            auf das nächste Level gebracht. Durch UV-C Desinfektion,
            Ozongenerator, Anti-Frost-System, antibakteriellem Material,
            integrierten Sitzpolstern, Timing Funktion und weiteren
            Ausstattungen gehören die Produkte aktuell zu den besten auf dem
            Markt.
          </p>

          <p>
            Die <strong>günstigsten Preise</strong>, sowie Exklusiv-Angebote
            erhalten Sie ausschließlich beim weltweit größten MSpa
            Partnerhändler{" "}
            <strong>
              <a
                style={{ color: "#219cd8", textDecoration: "underline" }}
                rel="noopener noreferrer"
                href="https://www.miweba.de/garten/outdoor-whirlpool/?sPartner=2131844"
                target="_blank"
              >
                Miweba.de
              </a>
            </strong>
          </p>

          <h3>4. Wie laut ist ein aufblasbarer Whirlpool?</h3>
          <p>
            Sobald der Whirlpool aufgeblasen und aktiv ist, ergeben sich
            folgende Dezibel-Werte (drei Schritte entfernt gemessen):
            <ul>
              <li>Filtrationsanlage aktiv: unter 60dB</li>
              <li>Wasserheizung aktiv: unter 60dB</li>
              <li>Massage System aktiv: unter 72dB</li>
            </ul>
            Bei hochwertigen, guten Pumpensystemen im Bereich von 600 bis 800
            Watt, liegt die Betriebslautstärke nicht über 50 Dezibel. Zur
            Referenz: 60 Dezibel entsprechen etwa der normalen
            Gesprächslautstärke zwischen zwei Menschen, welche wenige Schritte
            entfernt sind. 72 Dezibel ist etwa so laut wie ein Staubsauger.
          </p>
          <a
            href="https://www.miweba.de/garten/outdoor-whirlpool/23638/miweba-mspa-drop-stitch-whirlpool-2021-frame-mono-f-mo061-all-in-one-box-ozongenerator-6-personen?sPartner=2131844"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="img"
              width="100%"
              src="https://www.miweba.de/media/image/bb/88/14/whirlpool_blau_kupfer_mspa_mono_F_MO061_2021_leisehIf60SCg5Z7dS.jpg"
              alt=""
            />
          </a>

          <h3>5. Wie hoch sind die Stromkosten?</h3>
          <p>
            Als allgemeinen Richtwert kann man davon ausgehen, dass die
            Whirlpoolheizung etwa 0,5 bis 2 kW pro Stunde verbraucht. Die
            Massagefunktion verbraucht etwa 0,5 bis 0,72 kW pro Stunde und die
            Filterfunktion etwa 0,035 bis 0,06 kWh pro Stunde. Der genaue
            Verbrauch hängt selbstverständlich von vielen Faktoren ab.
          </p>
          <p>
            <strong> – Praxis Beispiel:</strong>
          </p>
          <p>
            Bei einer Verwendung von 30 Minuten pro Tag mit Filter- und
            Luftdüsenfunktion verursacht ein aufblasbarer Whirlpool
            durchschnittlich 0,10 bis 0,15 Stromkosten pro Tag. Das entspricht
            unter 100 Euro Stromkosten pro Jahr. (Nutzungszeit: Sommer)
          </p>
          <p>
            Bitte beachten Sie, dass hier die Heizkosten nicht eingerechnet
            sind. Sollten Sie den aufblasbaren Whirlpool auch bei kalten
            Temperaturen auf bis zu 40° C aufheizen wollen, müssen Sie mit
            jährlichen Stromkosten im Bereich von 200 und 500 Euro rechnen.
          </p>

          <h3>6. Kann man einen aufblasbaren Whirlpool im Winter verwenden?</h3>
          <p>
            Modelle, die mit dem innovativen Anti-Frost-System ausgestattet
            sind, können auch im Winter verwendet werden. Dieses System sorgt
            dafür, dass das Wasser automatisch aufgeheizt wird, sobald die
            Wassertemperatur unter 1°C fällt.
          </p>
          <a
            href="https://www.miweba.de/garten/outdoor-whirlpool/23638/miweba-mspa-drop-stitch-whirlpool-2021-frame-mono-f-mo061-all-in-one-box-ozongenerator-6-personen?sPartner=2131844"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="img"
              width="100%"
              src="https://www.miweba.de/media/image/e7/1a/d0/whirlpool_blau_kupfer_mspa_mono_F_MO061_2021_heizung.jpg"
            />
          </a>
          <h3>7. Was ist ein Ozongenerator?</h3>
          <p>
            Der Ozongenerator tötet einen Großteil der Bakterien im Wasser über
            die sogenannte Ozon-Desinfektion ab. Der Generator erzeugt das
            Ozongas, indem er Sauerstoff über eine Ultraviolettlampe
            ultraviolettem Licht aussetzt oder über ein elektronisches Gerät
            eine sogenannte Koronaentladung erzeugt. Diese Vorgehensweisen sind
            an die Abläufe angelehnt, die für die Ozonschicht der Erdatmosphäre
            sorgen.
          </p>
          <p>
            Einsatz im Pool: Der Generator fügt dem Wasser (H²O) im Whirlpool
            Ozongas (O³) hinzu. Da Ozon ein Molekül aus drei Sauerstoffatomen
            ist, überträgt es sein drittes Atom bei Kontakt an das Wasser bzw.
            an das wasserbenetzte Material. Dadurch werden Algen, Bakterien,
            Körperöle oder andere Verunreinigungen oxidiert oder verbrannt.
          </p>
          <a
            href="https://www.miweba.de/garten/outdoor-whirlpool/23638/miweba-mspa-drop-stitch-whirlpool-2021-frame-mono-f-mo061-all-in-one-box-ozongenerator-6-personen?sPartner=2131844"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="img"
              width="100%"
              src="https://www.miweba.de/media/image/50/b4/57/whirlpool_blau_kupfer_mspa_mono_F_MO061_2021_hygiene.jpg"
              alt=""
            />
          </a>

          <h3>8. Wie läuft die Filterreinigung und der Filterwechsel ab?</h3>
          <p>
            <strong>Filter anbringen:</strong> Schrauben Sie den Filter im
            Uhrzeigersinn an die Wasserversorgung. Sie finden die Wasserzufuhr
            an der Innenseite des Whirlpools. Die Löcher auf der Oberfläche der
            Filterkartusche stellen sicher, dass die Kartusche nicht blockiert
            wird und immer ein minimaler Wasserfluss vorhanden ist, sodass das
            Heizgerät oder die Pumpe nicht beschädigt werden.
          </p>
          <p>
            <strong>Filter reinigen & wechseln:</strong> Der Filter muss sauber
            gehalten und regelmäßig ausgetauscht werden, um die Lebensdauer der
            Filterpumpe zu verlängern. Die Reinigungsfrequenz kann an die
            Nutzungshäufigkeit angepasst werden. Wir empfehlen, immer zwei
            Filter zu verwenden, damit einer gereinigt werden kann, während der
            andere Filter aktiv im Pool verwendet wird. Spülen Sie den Filter
            zuerst gründlich aus und geben Sie ihn dann in einen Eimer mit
            Reinigungsmittel. Nach dem Einweichen können Sie den Filter erneut
            ausspülen und an der Luft trocknen lassen.
          </p>
          <p>
            Wenn Sie den Whirlpool regelmäßig benutzen, empfehlen wir, den
            Filter alle 3-5 Tage auszutauschen und zu reinigen.
          </p>
          <p>
            Die Lebensdauer einer einzelnen Filterkartusche beträgt ca. 14 Tage.
            (je nach Benutzung und Reinigung)
          </p>

          <h3>9. Kann der Whirlpool mit Salzwasser befüllt werden?</h3>
          <p>
            Befüllen Sie Ihren Garten-Whirlpool nicht mit Salzwasser! Nur
            spezielle dafür vorgesehene Salzwasser-Pools sind hierfür geeignet,
            da diese mit einer sogenannten Elektrolysepumpe und einem anders
            beschaffenen Material ausgestattet sind. Das Salzwasser ist
            wesentlich aggressiver zu den Whirlpool-Bestandteilen, wie der Pumpe
            und der Heizung. Zudem besteht durch Nutzung von Salzwasser eine
            erhöhte Korrosionsgefahr, wodurch Rost an metallischen Bauteilen
            entstehen kann.
          </p>
          <p>
            Bedenken Sie bitte, dass bei der Nutzung einer Salzwasseranlage
            durch Elektrolyse ebenfalls Chlor entsteht. Die Elektrolysepumpe
            spaltet das Salz nämlich in seine Bestandteile, die sich wiederum
            mit dem Wasser verbinden und aktives Chlor bilden. Ein Chlorgeruch
            entfällt somit bei der Nutzung von Salzwasser und einer
            Elektrolysepumpe nicht komplett. Auch die Kontrolle des pH-Wertes
            durch Teststreifen und ggf. pH-Senker sowie die Zugabe von Algiziden
            ist bei Salzwasser-Pools nötig.
          </p>

          <p style={{ fontSize: "11px" }}>
            * Einige ausgehende Links sind sogenannte Affiliate Links. Als
            Amazon Partner verdienen wir an qualifizierten Verkäufen.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
