import { useEffect, useState } from "react";
import Header from "../partials/header";
import db from "../firebase";
import LazyLoad from "react-lazyload";

function UrbanAurora() {
  const [ctaVisible, setCtaVisible] = useState(false);

  const linkClicked = (link) => {
    console.log("affiliate link clicked", link.href);
    const date = new Date();
    const dateString = date.toUTCString();

    const docRef = db.collection("stats").doc("linkClicks");
    let previousArray = [];

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data().lastClick);
          previousArray = doc.data().lastClick;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .then(() => {
        console.log(previousArray);

        // update document
        db.collection("stats")
          .doc("linkClicks")
          .set({
            lastClick: [
              ...previousArray,
              link.dataset.id + " | " + date.toUTCString(),
            ],
          })
          .then(function () {
            console.log("Document successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    const allLinks = document.querySelectorAll(".testbericht a");
    console.log(allLinks);

    for (let i = 0; i < allLinks.length; i++) {
      allLinks[i].addEventListener("click", (e) => {
        if (e.currentTarget.dataset.id) {
          console.log("dataset ID exists");
          linkClicked(e.currentTarget);
        } else {
        }
      });
    }

    return () => {};
  }, []);

  useEffect(() => {
    const scrollFunction = () => {
      if (window.scrollY > 70) {
        setCtaVisible(true);
      } else if (window.scrollY < 300) {
        setCtaVisible(false);
      }

      if (window.scrollY > 5300) {
        setCtaVisible(false);
      }
    };
    document.addEventListener("scroll", scrollFunction);
    return () => {
      document.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  return (
    <div className="app">
      <Header></Header>

      <div className="testbericht">
        <div className={"floatingCTA" + (ctaVisible ? "" : " hidden")}>
          <a
            data-id="Urban Aurora TEST Miweba Link floating"
            target="_blank"
            href="https://www.miweba.de/garten/outdoor-whirlpool/23588/miweba-mspa-whirlpool-2021-urban-aurora-u-au061-fuer-6-personen-mit-led-m-one-ozon-desinfektion?sPartner=2131844"
            className="buy-cta"
          >
            <span>Zum günstigsten Shop</span>
            <span class="cta-price">879,99 €</span>
          </a>
        </div>

        <div className="intro">
          <a className="back" href="/">
            <i className="fa fa-arrow-left"></i> Zurück zur Vergleichstabelle
          </a>

          <h1>MSpa Urban Aurora - Testbericht</h1>

          <p>
            Nach Berücksichtigung aller relevanten Vergleichskriterien ist der
            Miweba MSpa Urban Aurora U-AU061 der{" "}
            <strong>beste aufblasbare Whirlpool 2021</strong> und somit auf
            Platz 1 der Rangliste. Auch von der Beliebtheit (Januar bis April)
            liegt er mit großem Abstand weit vor allen anderen Modellen.
          </p>
          <img
            src="https://www.content.miweba.de/src/mspa-2021/aurora/mspa-aurora-emotion-banner-2.jpg"
            width="100%"
            alt="Miweba MSpa Urban Aurora U-AU061 in Betrieb"
          />

          <p>
            Beim Urban Aurora U-AU061 handelt es sich um die neue, verbesserte
            2021er Version des vorherigen MSpa Delight Aurora (nicht mehr
            verfügbar). Beide Whirlpools zeichnen sich durch ihre integrierte
            LED-Beleuchtung aus. Die Farben lassen sich allerdings nicht manuell
            einstellen, sondern laufen abwechselnd, was für viele eventuell ein
            kleiner Nachteil sein könnte.
          </p>

          <h3>
            <strong>Verbesserungen</strong>
          </h3>

          <p>
            Im Vergleich zum vorherigen Modell gibt es zahlreiche Erneuerungen.
            Dazu gehört einerseits der Einsatz von einem O3 Ozongenerator in
            Kombination mit UV-C Desinfektion. Das sorgt nicht nur dafür, dass
            rund 99% aller Bakterien im Wasser eliminiert werden, sondern
            verringert auch den Bedarf von Pflegemitteln wie Brom und Chlor um
            ca. 60-90%. Zusätzlich besteht der Whirlpool aus einem
            antibakteriellem Material nach ISO 22196-2011, was den Effekt
            nochmal zusätzlich verstärkt.
          </p>
          <p>
            Der eingesetzte Filter gehört zu den besten auf dem Markt und sorgt
            mit seinen 90 Lamellen für 0.3m³. Außerdem erhalten Sie wöchentlich
            eine “Filter Erinnerung”, die Sie daran erinnert, das Wasser sauber
            zu halten, oder den Kartuschenfilter zu wechseln. So glasklar war
            das Whirlpoolwasser noch nie, weshalb sich die neuen 2021er MSpa
            Modelle im Hygiene Aspekt auch hervorragend gegen die Bestway und
            Intex Konkurrenz durchsetzen konnten.{" "}
            <em>Salzwasser Whirlpools waren gestern!</em> 😉
          </p>

          <p>
            Mit dem sogenannten M-ONE System wurde der Aufbau des Whirlpools
            noch einfacher gestaltet. Nach dem Ausbreiten des Whirlpools müssen
            Sie lediglich auf die “Bubble” Taste drücken und der Whirlpool bläst
            sich innerhalb von 5 Minuten von selbst auf. Einfacher war der
            Aufbauprozess noch nie. Ist der Luftdruck zu hoch, können Sie diesen
            problemlos über das Manometerventil kontrollieren.
          </p>
          <p>
            Besonders praktisch ist die neue Timer Funktion. Damit können Sie
            die Nutzungszeit bis zu 99 Stunden im Voraus planen. Somit kann der
            Whirlpool geplant auf die Wunschtemperatur geheizt werden. Kein
            langes Warten mehr.
          </p>
          <h3>
            <strong>Fernbedienung</strong>
          </h3>
          <p>
            Gesteuert wird der Whirlpool über eine kabelgebundene wasserdichte
            LED-Fernbedienung. Darauf befinden sich vier Tasten für UV-C
            Desinfektion, Heizung, Filter und Timer, sowie darunter die zwei
            Tasten "Up" und "Down" für eine leichte intuitive Navigation. Auf
            der Oberseite befindet sich ein Display, das die aktuelle
            Wassertemperatur in Grad Celsius und Grad Fahrenheit auf eine
            Nachkommastelle genau anzeigt.
          </p>
          <img
            src="https://www.whirlpool-king.de/wp-content/uploads/2021/04/MSpa_Urban_Aurora_Fernbedienung_Foto_bei_Nacht.jpg"
            width="100%"
            alt="MSpa Urban Aurora LED Fernbedienung 2021"
          />

          <p>
            Bei manchen MSpa Modellen kommt anstatt einer Fernbedienung, eine
            All-in-One Kontrollbox zum Einsatz. Die Fernbedienung ist allerdings
            viel vorteilhafter, da Sie nicht extra aufstehen müssen, und den
            Whirlpool somit während dem Baden steuern können.
          </p>
          <h3>
            <strong>Heizung</strong>
          </h3>

          <p>
            Die 2021er Modelle, darunter auch der MSpa Urban Aurora, haben
            leider nur mehr eine Maximaltemperatur von 40° C (davor 42° C).
            Unserer Recherche zufolge liegt das an den{" "}
            <a
              href="https://rhtubs.com/resources/why-hot-tub-temperatures-max-out-at-104-degrees/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Gesetzen, welche diese Temperatur als Höchstwert vorschreiben
            </a>
            . Da die MSpa Whirlpools international vertrieben werden, ist diese
            Grenze auch überall einheitlich.
          </p>
          <p>
            Nichtsdestotrotz leistet die patentierte PTC-Heiztechnologie eine
            erstklassige Arbeit und erwärmt das Wasser rund 1.2 bis 1.8 Grad
            Celsius pro Stunde, was mit der Leistung von Intex oder Bestway
            Whirlpools nahezu identisch ist. Laut Herstellerangaben liegt die
            Heizleistung des MSpa Urban Aurora bei 1500 Watt (2.04 PS).
          </p>
          <h3>
            <strong>Sprudelfunktion</strong>
          </h3>

          <p>
            Die Luftdüsen sorgen für eine gleichmäßige und angenehme
            Sprudelmassage. Falls Sie sich fragen: Was bedeutet “360°
            Sprudelsystem?”. Gemeint sind die Luftdüsen, die sich rundherum
            seitlich am Boden befinden - siehe folgendes Bild:
          </p>
          <img
            src="https://i.gyazo.com/a8633d11381625b7d4305c84592a5ae9.png"
            width="100%"
            alt="Luftlöcher / Luftdüsen am Boden des Urban Aurora Whirlpools"
          />

          <p>
            Der entscheidende Vorteil beim MSpa Urban Aurora ist, dass man die
            Luftdüsen in drei Intensitätsstufen einstellen kann. 300 Watt:
            leicht, 500 Watt: mittel und 720 Watt: stark. Die höchste
            Intensitätsstufe entspricht laut Hersteller 0.7 PS.
          </p>
          <p>
            Leider sind bei diesem Modell keine Wasserdüsen vorhanden. Falls Sie
            ein Modell mit 6 Wasserdüsen suchen, empfehlen wir Ihnen den{" "}
            <a
              href="https://www.whirlpool-king.de/produkt/mspa-muse-otium-m-ot061/"
              rel="noopener noreferrer"
              target="_blank"
            >
              MSpa Muse Otium
            </a>
            .
          </p>
          <h3>
            <strong>Lautstärke</strong>
          </h3>

          <p>
            Bei aktivierten Luftdüsen sind wir bei unserem Test auf den
            Höchstwert von 69 Dezibel gekommen. Die eingeschaltete Heizfunktion
            ist hingegen nahezu lautlos. Konkurrenzprodukte von Bestway, Intex
            oder Arebos schneiden hier wesentlich schlechter ab.
          </p>
          <h3>
            <strong>Winterfest - ja oder nein?</strong>
          </h3>

          <p>
            Ja, dank dem Anti-Frost-System, das schon seit Jahren verwendet
            wird. Dieses sorgt dafür, dass der Whirlpool automatisch auf 3° C
            aufgeheizt wird, sobald die Temperatur unter 1° C fällt. Nachteil
            dabei ist, dass der Whirlpool immer eingeschaltet sein muss. Der
            Vorteil ist, dass keine Schäden am Material oder an der Pumpe
            entstehen kann.
          </p>
          <p>
            Bitte beachten Sie allerdings, dass die Verwendung im Winter mit
            wesentlich höheren Stromkosten einhergeht. Mit unserem{" "}
            <a
              href="https://www.whirlpool-king.de/aufblasbarer-whirlpool-stromkosten/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Stromkostenrechner
            </a>{" "}
            können Sie den erwarteten Verbrauch berechnen. Im Lieferumfang
            enthalten, ist außerdem eine aufblasbare Isolierabdeckung, die dafür
            sorgt, dass das Wasser nicht so schnell auskühlt. Sollten Sie den
            Whirlpool regelmäßig auf 30 bis 40 Grad erwärmen, empfehlen wir
            Ihnen unbedingt, diese Thermoabdeckung nach der Benutzung zu
            verwenden. Dadurch können Sie erheblich Stromkosten sparen.
          </p>
          <h3>
            <strong>Weitere Ausstattungen und Funktionen</strong>
          </h3>

          <p>
            Ein weiterer Pluspunkt sind die Seitenfächer, in die man
            beispielsweise die Fernbedienung oder das Smartphone hineinlegen
            kann. Beim Urban Aurora sind mehr Seitenfächer vorhanden, als
            beispielsweise beim zweitplatzierten MSpa Comfort Bergen.
          </p>
          <p>
            Auch Sitzpolster, sowie eine rutschfeste Bodenbeschichtung sind
            vorhanden. Unangenehmes Sitzen, sowie Rutschgefahr sind nämlich ein
            häufiges Problem bei minderwertigen aufblasbaren Whirlpools. Beim
            MSpa Urban Aurora U-AU061 müssen Sie sich darüber keine Sorgen
            machen - der Sitzkomfort ist ausgezeichnet und kann mühelos mit
            Konkurrenzprodukten dieser Preisklasse mithalten.
          </p>
          <img
            src="https://i.gyazo.com/068ab5ab439fcf86963192f4db14d07e.png"
            alt="miweba MSpa Aurora U-AU061 - 2021 Ausstattung, Hervorhebungen, Technologie, Vorteile"
            width="100%"
          />

          <h3>
            <strong>Wichtige Sicherheitshinweise</strong>
          </h3>

          <p>
            <ul>
              <li>
                Bevor Sie mit dem Aufbau der Whirlpools loslegen, raten wir
                Ihnen: Lesen Sie sich unbedingt das Benutzerhandbuch durch!
              </li>

              <li>
                Schließen Sie den Whirlpool keinesfalls mit einem
                Verlängerungskabel an. Dafür ist der Schutzstromschalter nicht
                vorgesehen.
              </li>

              <li>
                Über die PRCD Einheit (Fehlerstromschutz-Schalter) sollten Sie
                jedes mal vor Inbetriebnahme den “TEST” Knopf betätigen. Gilt
                dieser Test nicht als bestanden, darf der Whirlpool auf keinen
                Fall verwendet werden. In diesem Fall sollten Sie den Whirlpool
                vom Stromnetz trennen und einen professionellen Elektriker zur
                Hilfe holen.
              </li>

              <li>
                Öffnen Sie keinesfalls die PRCD Einheit. Damit erlischt die
                Herstellergarantie.
              </li>

              <li>
                Beachten Sie die Minimum und Maximum Linie für den Wasserstand
                auf der Innenseite des MSpa Urban Aurora. Die Grenzen dürfen
                nicht über- bzw. unterschritten werden.
              </li>

              <li>
                Verwenden Sie diesen Whirlpool nicht mit Salzwasser. Dadurch
                erlischt ebenfalls die Herstellergarantie.
              </li>
            </ul>
          </p>
          <h3>
            <strong>Fazit zum Miweba MSpa Urban Aurora</strong>
          </h3>

          <p>
            Dieser aufblasbare Whirlpool überzeugt in fast allen Punkten und ist
            somit der verdiente Test- bzw. Vergleichssieger für das Jahr 2021.
            Wie alle aktuellen MSpa Whirlpools, besitzt der ein TÜV Zertifikat,
            sowie das Siegel für Geprüfte Sicherheit (GS). Dieses Siegel ist ein
            Beleg dafür, dass das Produkt alle Punkte aus dem § 21 des
            Produktsicherheitsgesetzes (ProdSG) erfüllt. Er ist aktuell leider
            nur in einer Farbe, sowie in einer Größe erhältlich: Anthrazit, 6
            Personen.
          </p>
          <p>
            <strong>Vorteile:</strong>

            <ul
              id="list-icon-check"
              className="list-icon list-icon-check"
              title="Vorteile"
            >
              <li>UV-C Desinfektion und O3 Ozonator</li>
              <li>winterfest dank Anti-Frost-System</li>
              <li>Sitzpolster und rutschfester Boden</li>
              <li>PTC-Heizung mit 1.2 - 1.8°C pro Stunde</li>
              <li>kinderleichter Aufbau dank M-ONE System</li>
              <li>Isolierabdeckung im Lieferumfang</li>
              <li>Kindersicherung vorhanden</li>
              <li>Timer & Filter Reminder</li>
              <li>TÜV und GS Zertifikat</li>
              <li>Luftdüsen in 3 Stufen verstellbar</li>
              <li>sehr leise bei aktivierter Sprudelfunktion</li>
            </ul>
          </p>
          <p>
            <strong>Nachteile:</strong>

            <ul
              id="list-icon-check"
              className="list-icon list-icon-cross"
              title="Nachteile"
            >
              <li>LED-Beleuchtung nicht verstellbar</li>
              <li>Keine Wasserdüsen vorhanden</li>
              <li>Maximaltemperatur “nur” 40°C</li>
              <li>nur in einer Farbe & Größe erhältlich</li>
              <li>kann nicht an Verlängerungskabel angeschlossen werden</li>
              <li>Keine Auto-Filterfunktion mehr</li>
            </ul>
          </p>
          <a
            data-id="Urban Aurora TEST Miweba Link"
            target="_blank"
            href="https://www.miweba.de/garten/outdoor-whirlpool/23588/miweba-mspa-whirlpool-2021-urban-aurora-u-au061-fuer-6-personen-mit-led-m-one-ozon-desinfektion?sPartner=2131844"
            className="buy-cta"
          >
            Zum günstigsten Anbieter*
          </a>
          <span
            style={{
              textAlign: "center",
              fontSize: "12px",
              display: "block",
              marginTop: "5px",
              opacity: "0.7",
            }}
          >
            Der Kaufprozess ist optimiert für Smartphones.
          </span>
          <a
            style={{ marginTop: "25px", display: "block", textAlign: "center" }}
            className="back"
            href="/"
          >
            <i className="fa fa-arrow-left"></i> Zurück zur Vergleichstabelle
          </a>
        </div>
      </div>
    </div>
  );
}

export default UrbanAurora;
