import { useEffect, useState } from "react";
import Header from "../partials/header";
import db from "../firebase";
import LazyLoad from "react-lazyload";

function Testbericht() {
  const [ctaVisible, setCtaVisible] = useState(false);

  const linkClicked = (link) => {
    console.log("affiliate link clicked", link.href);
    const date = new Date();
    const dateString = date.toUTCString();

    const docRef = db.collection("stats").doc("linkClicks");
    let previousArray = [];

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data().lastClick);
          previousArray = doc.data().lastClick;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .then(() => {
        console.log(previousArray);

        // update document
        db.collection("stats")
          .doc("linkClicks")
          .set({
            lastClick: [
              ...previousArray,
              link.dataset.id + " | " + date.toUTCString(),
            ],
          })
          .then(function () {
            console.log("Document successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
          });
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    const allLinks = document.querySelectorAll(".testbericht a");
    console.log(allLinks);

    for (let i = 0; i < allLinks.length; i++) {
      allLinks[i].addEventListener("click", (e) => {
        if (e.currentTarget.dataset.id) {
          console.log("dataset ID exists");
          linkClicked(e.currentTarget);
        } else {
        }
      });
    }

    return () => { };
  }, []);

  useEffect(() => {
    const scrollFunction = () => {
      if (window.scrollY > 70) {
        setCtaVisible(true);
      } else if (window.scrollY < 300) {
        setCtaVisible(false);
      }

      if (window.scrollY > 5300) {
        setCtaVisible(false);
      }
    };
    document.addEventListener("scroll", scrollFunction);
    return () => {
      document.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  return (
    <div className="app">
      <Header></Header>

      <div className="testbericht">
        <div className={"floatingCTA" + (ctaVisible ? "" : " hidden")}>
          <a
            data-id="Comfort Bergen TEST Miweba Link"
            target="_blank"
            href="https://www.miweba.de/garten/outdoor-whirlpool/22921/miweba-mspa-whirlpool-2021-comfort-bergen-c-be061-garten-outdoor-whirlpool-aufblasbar-6-personen?sPartner=2131844"
            className="buy-cta"
          >
            <span>Zum günstigsten Shop</span>
            <span class="cta-price">639,99 €</span>
          </a>
        </div>

        <div className="intro">
          <a className="back" href="/">
            <i className="fa fa-arrow-left"></i> Zurück zur Vergleichstabelle
          </a>

          <h1>MSpa Comfort Bergen - Testbericht</h1>

          <p>
            Der Comfort Bergen C-BE061 der 2021er MSpa Serie gehört aktuell zu
            den besten aufblasbaren Whirlpools. In unserem Vergleich liegt er
            auf <strong>Platz 2</strong> mit der Auszeichnung{" "}
            <strong>"Preis-Leistungs Hit"</strong>. Er ist das günstigste MSpa
            Modell, besitzt aber dennoch eine umfangreiche Ausstattung.
          </p>
          <p>
            In diesem Test zeigen wir Ihnen, was das Modell zu bieten hat, und
            warum sich der Kauf lohnt.
            <LazyLoad>
              <img
                src="/MSpa_Comfort_Bergen_zwei_personen_baden_im_whirlpool_sommer-1.jpg"
                alt=""
              />
            </LazyLoad>
            {/*<img
              width="100%"
              src="https://media.xxxlutz.com/i/xxxlutz/PIwNReJiy5L8lNGccOUl8f-Q/?fmt=auto&w=1000&h=0&upscale=false"
              alt=""
            />*/}
            <figcaption>MSpa Comfort Bergen - Farbe: Anthrazit/Blau</figcaption>
          </p>
          <h2>Ausstattung & Funktionen</h2>
          <p>
            Mit den 2021er Modellen sind zahlreiche neue Funktionen
            hinzugekommen, die den Standard auf ein neues Level bringen. Eine
            vollständige Auflistung der Ausstattung:
            <ul>
              <li>Anti-Frost-System</li>
              <li>UV-C Desinfektion</li>
              <li>Auto Timer Funktion</li>
              <li>Filter Alarmfunktion</li>
              <li>Antibakterielles Material</li>
              <li>PTC-Heiztechnologie</li>
              <li>LED-Fernbedienung</li>
              <li>360° Sprudelsystem</li>
            </ul>
          </p>
          <p>
            <strong>Anti-Frost-System</strong>
          </p>
          <p>
            Das Anti-Frost-System ist bereits seit Jahren verbaut und macht den
            Whirlpool winterfest. Es sorgt dafür, dass das Wasser automatisch
            auf 3°C aufgeheizt wird, sobald es unter 1°C fällt. Somit kann das
            Wasser nicht gefrieren und es können keine Schäden an Pumpe oder
            Düsen entstehen.
          </p>
          {/*<p>
            <strong>UV-C Desinfektion & Ozongenerator</strong>
          </p>
          <p>
            Eine Innovation der 2021er Modelle ist die Kombination aus UV-C
            Desinfektion und Ozongenerator. Bei der UV-C Desinfektion werden
            durch ultravioletes Licht 95% aller Bakterien, Viren und
            Krankheitserreger effektiv beseitigt. Der Vorteil davon ist, dass
            Sie weniger chemische Reinigungsmittel verwenden müssen! Das
            Material ist außerdem antibakteriell nach ISO 22196-2011, wodurch
            die Keimbildung um ganze 99% verringert wird.
          </p>*/}
          <p>
            <strong>UV-C Desinfektion</strong>
          </p>
          <p>
            Eine Innovation der 2021er Modelle ist die Kombination aus UV-C
            Desinfektion und antibakteriellem Material. Bei der UV-C
            Desinfektion werden durch ultravioletes Licht 95% aller Bakterien,
            Viren und Krankheitserreger effektiv beseitigt. Der Vorteil davon
            ist, dass Sie weniger chemische Reinigungsmittel verwenden müssen!
            Das Material ist antibakteriell nach ISO 22196-2011, wodurch die
            Keimbildung um ganze 99% verringert wird.
          </p>

          <p>
            <strong>Timer Funktion</strong>
          </p>
          <p>
            Mit der Timer Funktion können Sie die Nutzungszeit um bis zu 99
            Stunden im Voraus einstellen bzw. planen. So kann der Whirlpool
            bereits optimal vorgeheizt werden.
          </p>
          <p>
            <strong>PTC-Heizung</strong>
          </p>
          <p>
            Im Vergleich zu früheren 2020er MSpa Whirlpools (zB. Elite Baikal
            oder Delight Aurora) schaffen die neuen Modelle nur eine
            Maxmialtemperatur von 40°C (anstatt 42°C). Es ist aber dennoch die
            patentierte PTC-Heiztechnologie verbaut. Die Heizleistung entspricht
            beim Comfort Bergen, wie bei fast allen 2021er MSpa Whirlpools, etwa
            1,2 bis 1,8 Grad Celsius pro Stunde.
          </p>
          <p>
            <strong>Fernbedienung</strong>
          </p>
          <p>
            Gesteuert wird das System über eine wasserdichte All-in-One
            LED-Fernbedienung, mit welcher man UVC-Desinfektion, Heizung,
            Luftdüsen, die Zeitschaltuhr und die Pumpe bedienen kann. Oben wird
            die aktuelle Wassertemperatur in Grad Celsius und Fahrenheit
            angezeigt. Auf der Unterseite befinden sich die Tasten "Up" und
            "Down", welche der einfachen Navigation dienen.
            <LazyLoad>
              <img
                src="https://media.xxxlutz.com/i/xxxlutz/PIdsV9gO-jRzM6qNJyiDLzyA/"
                alt=""
              />
            </LazyLoad>
            <figcaption>
              Fernbedienung zur Steuerung aller Funktionen
            </figcaption>
          </p>

          <h2>Zertifikate</h2>

          <p>
            Der MSpa Comfort Bergen besitzt das TÜV Süd Zertifikat und das GS
            Zertifikat für Geprüfte Sicherheit.
          </p>
          <p>
            Dieses Siegel ist ein Beleg dafür, dass das Produkt alle Punkte aus
            dem § 21 des Produktsicherheitsgesetzes (ProdSG) erfüllt.
          </p>

          <h2>Aufbau & Installation</h2>

          <p>
            Auch der Aufbau wird mit der 2021er Serie leichter denn je. Durch
            das M-ONE System baut sich der Whirlpool mit nur einem Knopfdruck
            innerhalb 5 Minuten selbst auf.
          </p>
          <p>
            <strong>Vorbereitung:</strong>
          </p>

          <p>
            Vor dem Aufbau sollten Sie darauf achten, einen ebenen Untergrund zu
            suchen. Zudem sollten sich keine Steine, Sand oder sonstige spitze
            Gegenstände auf dem Untergrund befinden. Ideal geeignet ist eine
            Terrasse oder eine Bodenmatte als Unterlage.
          </p>

          <p>
            <strong>So läuft der Aufbau ab:</strong>
          </p>

          <p>
            Der gesamte Whirlpool ist zunächst in einem Sack verpackt. Diesen
            müssen Sie öffnen und anschließend den Whirlpool aufbreiten. Legen
            Sie ihn am besten gleich an die richtige Position, denn sobald der
            Pool erstmal mit Wasser gefüllt ist, kann er nicht mehr verschoben
            werden.
          </p>

          <p>
            Entfernen Sie nun die Verpackung des Stromkabels und rollen Sie
            dieses auf. Wichtiger Hinweis: Schließen Sie das Stromkabel,
            wennmöglich nicht an ein Verlängerungskabel an.
          </p>

          <p>
            Stecken Sie das Stromkabel nun in die Steckdosen. Als nächstes
            drücken Sie den grünen "RESET" Knopf an der Stromkabel-Einheit,
            sodass das Rote Licht darunter leuchtet. Drücken Sie jetzt auf den
            gelben "TEST" Knopf. Wenn das rote Licht ausgeht, funktioniert alles
            einwandfrei.
          </p>

          <p>
            Schließen sie nun den Schlauch an, wie im nachfolgendem Bild
            gezeigt:
            <img
              src="https://media.xxxlutz.com/i/xxxlutz/PIvJUtl1-AsUpAHGDU4KlyOw/"
              alt=""
            />
          </p>

          <p>
            Anschließend entfernt man die Verpackung von der Fernbedienung, über
            diese wird der Aufbau nämlich gesteuert bzw. gestartet. Drücken Sie
            nun auf die "Bubble" Taste, damit der Whirlpool beginnt, sich selbst
            aufzublasen. Dieser Prozess kann einige Minuten dauern.
          </p>

          <p>
            Ist der Whirlpool ausreichend aufgeblasen, drücken Sie erneut auf
            die "Bubble" Taste, um den Vorgang zu stoppen. Ist zu viel Luft im
            Whirlpool, ist das auch kein Problem. Über das Ventil können sie
            ganz einfach Luft ablassen.
          </p>
          <p>
            Der Grün-gelbe Kolben zeigt Ihnen den Luftdruck. Idealerweise sollte
            der grüne Bereich nicht zu sehen sein.
          </p>
          <LazyLoad>
            <img
              src="https://media.xxxlutz.com/i/xxxlutz/PI2i0f00OtccBDXFZvSk5zwQ/"
              alt=""
            />
          </LazyLoad>
          <figcaption>Aufbau / Manometer & Ventil</figcaption>

          <p>
            Befestigen Sie nun den Filter an der Innenseite des Whirlpools, wie
            im nachfolgenden Bild:
          </p>
          <LazyLoad>
            <img
              src="https://media.xxxlutz.com/i/xxxlutz/PIpZvlV-K3fxO5Nv5F78zH7g/"
              alt=""
            />
          </LazyLoad>
          <p>
            Bevor das Wasser eingelassen werden kann, muss das Ventil am Boden
            geschlossen werden. Die Linien auf der Innenseite zeigen Ihnen den
            minimalen und den maximalen Wasserstand. Diese Grenzen sollten
            unbedingt beachtet werden.
          </p>

          <p>
            Die vielen kleinen Löcher an der Unterseite des Whirlpools sind die
            Luftdüsen (360° Sprudelsystem), die für das Wellness Erlebnis
            sorgen:
          </p>
          <LazyLoad>
            <img
              src="https://i.gyazo.com/a8633d11381625b7d4305c84592a5ae9.png"
              alt=""
            />
          </LazyLoad>

          <p>
            Sobald Sie sich sicher sind, wo der Whirlpool platziert werden soll,
            können Sie das Wasser einfüllen. Hier gibt es nicht sonderlich viel
            zu beachten. Halten Sie einen Wasserschlauch in den Pool und warten
            Sie, bis sich der Wasserstand zwischen den beiden Grenzlinien
            befindet.
          </p>
          <LazyLoad>
            <img
              src="https://media.xxxlutz.com/i/xxxlutz/PIwNReJiy5L8lNGccOUl8f-Q/"
              alt=""
            />
          </LazyLoad>

          <h2>Wellness Faktor</h2>
          <p>
            Die Luftdüsen sind sehr angenehm und sorgen für ein angenehmes
            Whirlpool Erlebnis. Verglichen mit einem herkömmlichen Whirlpool für
            mehrere Tausende Euro ist (unserer Meinung) wirklich sehr wenig
            Unterschied festzustellen. Die Außenwände sind angenehm weich und
            durch den gepolsterten Boden kann man ohne Probleme längere Zeit im
            Whirlpool sitzen. Außerdem ist eine rutschfeste Bodenbeschichtung
            vorhanden.
          </p>

          <p>
            Beim Comfort Bergen sind - wie bei fast allen MSpa Modellen - keine
            Wasserdüsen vorhanden. Falls Sie ein vergleichbares Modell mit
            Wasserdüsen suchen, empfehlen wir Ihnen den{" "}
            <a
              data-id="Muse Otium Link inside CB TEST"
              href="https://www.miweba.de/garten/outdoor-whirlpool/23649/miweba-mspa-whirlpool-2021-muse-otium-m-ot061-hydro-massage-m-one-ozon-uv-c-reinigung-6-personen?sPartner=2131844"
              className="link"
              target="_blank"
            >
              <strong>MSpa Muse Otium</strong>
            </a>
            , welcher 6 winkleverstellbare Hydrojets besitzt.
          </p>

          <h2>Stromkosten</h2>
          <p>
            Am meisten Strom verbraucht beim Whirlpool die Heizung.
            Glücklicherweise ist diese im Sommer nicht unbedingt notwendig, da
            sich die meisten Menschen lieber abkühlen möchten. Sollten Sie den
            Whirlpool auch bei kalten Temperaturen nutzen wollen, werden die
            Stromkosten in die Höhe gehen.
          </p>
          <p>
            Mit 0.5 kW/h kostet 1 Stunde Luftdüsen ca. 0.16 Euro. Die
            Filterfunktion verbraucht mit 0.035 kW/h am wenigsten. 1 Stunde
            Heizfunktion (0.75 kW/h) kostet Sie etwa 0.24 Euro. Den Whirlpool 10
            Grad wärmer zu heizen, kostet also ca. 1.60 Euro.
          </p>

          <h2>Das Fazit</h2>

          <p>
            Mit diesem aufblasbaren Whirlpool können Sie garantiert nichts
            falsch machen. Er ist preiswert, hochwertig und ideal geeignet, um
            nach einem stressigen Tag so richtig runter zu kommen, zu entspannen
            und den Kopf abzuschalten.
          </p>

          <p>
            <strong>Unterschiede zum Urban Aurora</strong>
          </p>

          <p>
            Beim aktuellen Platz 1, dem{" "}
            <a
              data-id="Urban Aurora Link inside CB TEST"
              className="link"
              href="https://www.miweba.de/garten/outdoor-whirlpool/23588/miweba-mspa-whirlpool-2021-urban-aurora-u-au061-fuer-6-personen-mit-led-m-one-ozon-desinfektion?sPartner=2131844"
              target="_blank"
            >
              <strong>MSpa Urban Aurora</strong>
            </a>
            , kann man die Luftdüsen in 3 Stufen einstellen. Zudem ist am
            Whirlpool ein Seitenfach vorhanden und im Lieferumfang sind
            zusätzlich ein LED-Lichtschlauch, eine aufblasbare Abdeckung, sowie
            ein Bodenmatte inkludiert.
          </p>

          <p style={{ marginTop: "50px" }}>
            Das{" "}
            <strong>
              <a
                data-id="Comfort Bergen TEST 4P Miweba Link"
                className="link"
                href="https://www.miweba.de/garten/outdoor-whirlpool/22920/miweba-mspa-whirlpool-2021-comfort-bergen-c-be041-garten-outdoor-whirlpool-aufblasbar-4-personen?sPartner=2131844"
                target="_blank"
              >
                kleinere Modell für 4 Personen
              </a>
            </strong>{" "}
            ist bereits ab 539,99 Euro verfügbar.
          </p>

          <a
            data-id="Comfort Bergen TEST Miweba Link"
            target="_blank"
            href="https://www.miweba.de/garten/outdoor-whirlpool/22921/miweba-mspa-whirlpool-2021-comfort-bergen-c-be061-garten-outdoor-whirlpool-aufblasbar-6-personen?sPartner=2131844"
            className="buy-cta"
          >
            Zum günstigsten Anbieter*
          </a>
          <span
            style={{
              textAlign: "center",
              fontSize: "12px",
              display: "block",
              marginTop: "5px",
              opacity: "0.7",
            }}
          >
            Der Kaufprozess ist optimiert für Smartphones.
          </span>
          <a
            style={{ marginTop: "25px", display: "block", textAlign: "center" }}
            className="back"
            href="/"
          >
            <i className="fa fa-arrow-left"></i> Zurück zur Vergleichstabelle
          </a>
        </div>
      </div>
    </div>
  );
}

export default Testbericht;
