import React, { useState, useEffect } from "react";
import "./table.css";
import { useSwipeable } from "react-swipeable";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Modal from "./modal";
import LazyLoad from "react-lazyload";
import db from "../firebase";

import mono_c from "../mono_c.png";

const TableEmbed = () => {
  const [tablePosition, setTablePosition] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalURL, setModalURL] = useState("");

  const [prevArray, setPrevArray] = useState([]);
  const tableItems = 8;

  const handleModalShow = () => {
    setModalStatus(false);
  };

  const tableSwipe = (direction) => {
    //max -66% (3 Columns)
    if (
      direction == "right" &&
      tablePosition > tableItems * ((100 / tableItems) * -1)
    ) {
      setTablePosition(tablePosition - 100 / tableItems);
    }
    //min 0
    if (direction == "left" && tablePosition < 0) {
      setTablePosition(tablePosition + 100 / tableItems);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => tableSwipe("right"),
    onSwipedRight: () => tableSwipe("left"),
  });

  const linkClicked = (link) => {

    const date = new Date();
    const dateString = date.toUTCString();

    const docRef = db.collection("stats").doc("linkClicks");
    let previousArray = [];

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {

          previousArray = doc.data().lastClick;
        } else {
          // doc.data() will be undefined in this case

        }
      })
      .then(() => {
        console.log(previousArray);

        // update document
        db.collection("stats")
          .doc("linkClicks")
          .set({
            lastClick: [
              ...previousArray,
              link.dataset.id + " | " + date.toUTCString(),
            ],
          })
          .then(function () {

          })
          .catch(function (error) {

          });
      })
      .catch(function (error) {

      });
  };

  useEffect(() => {
    const allLinks = document.querySelectorAll(".tableContainer a");
    console.log(allLinks);

    for (let i = 0; i < allLinks.length; i++) {
      allLinks[i].addEventListener("click", (e) => {
        linkClicked(e.currentTarget);
      });
    }

    return () => { };
  }, []);

  return (
    <div className="tableContainer" {...swipeHandlers}>
      <Modal
        onStatusChange={() => {
          handleModalShow();
        }}
        show={modalStatus}
        url={modalURL}
      />

      <div className="arrows">
        <span title="Tabelle nach links navigieren" className="arrow-left" onClick={() => tableSwipe("left")}>
          <img alt="Links" src="https://i.gyazo.com/a9e886f27a487cbd1afce0b4e4e08ea9.png" />
        </span>
        <span title="Tabelle nach rechts navigieren" className="arrow-right" onClick={() => tableSwipe("right")}>
          <img alt="Rechts" src="https://i.gyazo.com/a9e886f27a487cbd1afce0b4e4e08ea9.png" />
        </span>

      </div>

      <ol
        className="table"
        style={{ transform: "translate(" + tablePosition + "%)" }}
      >
        <li className="column">
          <div className="row row-rank">
            <span className="badge">Meistverkauft 2021</span> 1
          </div>
          <div className="row row-image">
            <a
              target="_blank"
              rel=""
              href="https://www.whirlpool-king.de/produkt/miweba-mspa-urban-aurora-u-au061/"
            >
              <img src="https://www.whirlpool-king.de/wp-content/uploads/2021/02/MSpa_Urban_Aurora_U-AU061_whirlpool_aufblasbar-162x138.png" />
            </a>
          </div>
          <div className="row row-title">
            <a
              rel=""
              href="https://www.whirlpool-king.de/produkt/miweba-mspa-urban-aurora-u-au061/"
              target="_blank"
            >
              Miweba MSpa Urban Aurora U-AU061
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut - MSpa Urban Aurora"
              onClick={() => {
                window.open(
                  "https://www.whirlpool-king.de/produkt/miweba-mspa-urban-aurora-u-au061/",
                  "_blank"
                );
              }}
              src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg"
            />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <span>5 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img alt="TÜV Zertifikat" src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png" />
              <img alt="GS Siegel" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png" />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/YBoDAwqx6Ng");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>rund</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>204 x 70 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Rhino-Tech PVC</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>6</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>Ø 930 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.2 - 1.8° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>5 min.</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>138</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span>300/500/720 Watt</span>
          </div>
          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              <abbr
                data-tip="Eliminiert Bakterien im Wasser"
                data-event="click"
                data-event-off="dblclick"
              >
                Ozongenerator
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              <abbr
                data-tip="Minus 99% Keimbildung"
                data-event="click"
                data-event-off="dblclick"
              >
                UV-C Desinfektion
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="yes">
              <i class="fas fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              <abbr
                data-tip="M-ONE System - Ein-Klick Aufbau"
                data-event="click"
                data-event-off="dblclick"
              >
                Selbstaufblasend
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>

          <div className="row row-buy">
            <label>Günstigster Preis (Heute)</label>
            <ol>
              <li>
                <a
                  data-id="Mspa Urban Aurora - Miweba Link"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://www.miweba.de/garten/outdoor-whirlpool/23588/miweba-mspa-whirlpool-2021-urban-aurora-u-au061-fuer-6-personen-mit-led-m-one-ozon-desinfektion?sPartner=2131844"
                >
                  <div className="btn-buy">
                    <div className="shop-img">
                      <img alt="Miweba.de - Online Shop"
                        src="https://www.whirlpool-king.de/wp-content/uploads/2020/05/miweba_shop_logo-300x70.png"
                        alt=""
                      />
                    </div>

                    <span>
                      779,99 € <span className="versand">59,90 € Versand</span>
                    </span>
                  </div>
                </a>

              </li>

              <li>
                <a
                  data-id="Mspa Urban Aurora - Otto Link"
                  href="https://www.awin1.com/cread.php?awinmid=14336&awinaffid=676027&ued=https%3A%2F%2Fwww.otto.de%2Fp%2Fmspa-whirlpool-urban-aurora-u-au061-aufblasbarer-outdoor-pool-led-beleuchtung-integrierter-ozongenerator-uv-c-wasserreinigung-fuer-6-personen-S090X0S1%2F%23variationId%3DS090X0S1XKNG"
                  target="_blank"
                >
                  <div className="btn-buy">
                    <div className="shop-img">
                      <img
                        style={{ width: "70%" }}
                        src="https://www.otto.de/san/resources/san/img/header/otto_logo_2015.png"
                        alt="Otto.de - Online-Shop"
                      />
                    </div>
                    <span>
                      899,99 € <span className="versand">Gratis Versand</span>
                    </span>
                  </div>
                </a>

              </li>

              <li>
                <a
                  data-id="Mspa Urban Aurora - Amazon Link"
                  href="https://www.amazon.de/dp/B08X12XR7Q?&linkCode=ll1&tag=whirlpool-king-split-21&linkId=bdfbc74f03869838cde5fc4739fa0c0a&language=de_DE&ref_=as_li_ss_tl"
                  target="_blank"
                >
                  <div className="btn-buy">
                    <div className="shop-img">
                      <img
                        src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                        alt="Amazon.de - Online-Shop"
                      />
                    </div>
                    <span>
                      899,99 € <span className="versand">9,90 € Versand</span>
                    </span>
                  </div>
                </a></li>
            </ol>

          </div>
        </li>

        <li className="column">
          <div className="row row-rank">
            <span className="badge red">Preis-Leistungs Hit</span> 2
          </div>
          <div className="row row-image">
            <a
              href="https://www.whirlpool-king.de/produkte/miweba-mspa-bergen-c-be061/"
              target="_blank"
              rel=""
            >
              <img alt="MSpa Comfort Bergen C-BE061" src="https://cdn.vergleich.org/img/comparison-tables/miweba-mspa-2021-comfort-bergen-c-be41.jpg" />
            </a>
          </div>
          <div className="row row-title">
            <a
              href="https://www.whirlpool-king.de/produkte/miweba-mspa-bergen-c-be061/"
              target="_blank"
              rel=""
            >
              Miweba MSpa Comfort Bergen C-BE061
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut"
              onClick={() => {
                window.open(
                  "https://www.whirlpool-king.de/produkte/miweba-mspa-bergen-c-be061/",
                  "_blank"
                );
              }}
              src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg"
            />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star-half-alt"></i>
            </div>
            <span>4.5 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img alt="TÜV Zertifikat" src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png" />
              <img alt="GS Siegel" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png" />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/lF0Lf-_XRj4");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>rund</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>204 x 70 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Rhino-Tech PVC</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>6</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>Ø 930 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.2 - 1.8° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>5 min.</span>
          </div>

          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>138</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="yes">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>
              <abbr
                data-tip="M-ONE System - Ein-Klick Aufbau"
                data-event="click"
                data-event-off="dblclick"
              >
                Selbstaufblasend
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li> <a
                data-id="Mspa Comfort Bergen - Miweba Link"
                href="https://www.miweba.de/garten/outdoor-whirlpool/22921/miweba-mspa-whirlpool-2021-comfort-bergen-c-be061-garten-outdoor-whirlpool-aufblasbar-6-personen?sPartner=2131844"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://www.whirlpool-king.de/wp-content/uploads/2020/05/miweba_shop_logo-300x70.png"
                      alt="Miweba.de Online Shop"
                    />
                  </div>

                  <span>
                    589,99 € <span className="versand">29,90 € Versand</span>
                  </span>
                </div>
              </a>
              </li>
              <li>

                <a
                  data-id="Mspa Comfort Bergen - Otto Link"
                  href="https://www.awin1.com/cread.php?awinmid=14336&awinaffid=676027&ued=https%3A%2F%2Fwww.otto.de%2Fp%2Fmspa-whirlpool-comfort-bergen-c-be061-aufblasbarer-outdoor-pool-extra-dickes-rhino-tech-6-schicht-pvc-138-luftduesen-204-0-x-204-0-x-70-0-cm-fuer-6-personen-S0U0L05O%2F"
                  target="_blank" rel="nofollow"
                >
                  <div className="btn-buy">
                    <div className="shop-img">
                      <img
                        style={{ width: "70%" }}
                        src="https://www.otto.de/san/resources/san/img/header/otto_logo_2015.png"
                        alt="Otto.de Online Shop"
                      />
                    </div>
                    <span>
                      649,99 € <span className="versand">Gratis Versand</span>
                    </span>
                  </div>
                </a>

              </li>

              <li>  <a
                data-id="Mspa Comfort Bergen - Amazon Link"
                target="_blank"
                href="https://www.amazon.de/dp/B08PDHRWL4?tag=whirlpool-king-split-21&linkCode=ogi&th=1&psc=1"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon.de Online-Shop"
                    />
                  </div>

                  <span>
                    649,99 € <span className="versand">Gratis Versand</span>
                  </span>
                </div>
              </a></li>
            </ol>



          </div>
        </li>

        {/* PLATZ 3 */}

        <li className="column">
          <div className="row row-rank">
            <span className="badge green">Luxus Modell</span> 3
          </div>
          <div className="row row-image">
            <a

              href="https://www.whirlpool-king.de/produkt/miweba-mspa-frame-mono-f-mo061/"
              target="_blank"

            >
              <img alt="miweba MSpa Frame Mono F-MO061" src={mono_c} />
            </a>
          </div>
          <div className="row row-title">
            <a

              href="https://www.whirlpool-king.de/produkt/miweba-mspa-frame-mono-f-mo061/"
              target="_blank"
            >
              Miweba MSpa Frame Mono F-MO069
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut" src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg" />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <span>4.7 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img alt="TÜV" src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png" />
              <img alt="GS (geprüfte Sicherheit)" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png" />
            </div>
          </div>

          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/O7CBR4KdUhk");
              }}
              class="fab fa-youtube"
            ></i>
          </div>

          <div className="row row-spec">
            <label>Form </label>
            <span>rund</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>173 x 65 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Rhino-Tech PVC</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>6</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>Ø 930 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.2 - 1.8° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>5 min.</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>138</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span>300/500/720 Watt</span>
          </div>
          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              <abbr
                data-tip="M-ONE System - Ein-Klick Aufbau"
                data-event="click"
                data-event-off="dblclick"
              >
                Selbstaufblasend
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li> <a
                data-id="Mspa Frame Mono - Miweba Link"
                href="https://www.miweba.de/garten/outdoor-whirlpool/23638/miweba-mspa-drop-stitch-whirlpool-2021-frame-mono-f-mo061-all-in-one-box-ozongenerator-6-personen?sPartner=2131844"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://www.whirlpool-king.de/wp-content/uploads/2020/05/miweba_shop_logo-300x70.png"
                      alt="Miweba Online-Shop"
                    />
                  </div>

                  <span>
                    1.249,99 € <span className="versand">59,90 € Versand</span>
                  </span>
                </div>
              </a>
              </li>

              <li><a
                data-id="Mspa Frame Mono - Otto Link"
                href="https://www.awin1.com/cread.php?awinmid=14336&awinaffid=676027&ued=https%3A%2F%2Fwww.otto.de%2Fp%2Fmspa-whirlpool-frame-mono-f-mo061-aufblasbarer-outdoor-whirlpool-extra-dickes-rhino-tech-6-schicht-pvc-138luftduesen-173-x-173-x-65-cm-fuer-6-personen-S0B0Y02T%2F%23variationId%3DS0B0Y02TKZAX"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      style={{ width: "70%" }}
                      src="https://www.otto.de/san/resources/san/img/header/otto_logo_2015.png"
                      alt="Otto.de Online-Shop"
                    />
                  </div>
                  <span>
                    1399,99 € <span className="versand">Gratis Versand</span>
                  </span>
                </div>
              </a>
              </li>
              <li><a
                data-id="Mspa Frame Mono - Amazon Link"
                href="https://www.amazon.de/Unbekannt-aufblasbarer-Whirlpool-Tekapo-Heizfunktion/dp/B07V7CKVPV?dchild=1&keywords=Miweba&qid=1617127617&sr=8-35&linkCode=sl1&tag=whirlpool-king-split-21&linkId=664e6ee29cda72ea92199e13e9a9bcad&language=de_DE&ref_=as_li_ss_tl"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon Online-Shop"
                    />
                  </div>

                  <span>
                    1399,99 € <span className="versand">399 € Versand</span>
                  </span>
                </div>
              </a></li>
            </ol>



          </div>
        </li>

        {/* PLATZ 4 */}

        <li className="column">
          <div className="row row-rank">
            <span className="badge">für 2 Personen</span> 4
          </div>
          <div className="row row-image">
            <a

              href="https://www.whirlpool-king.de/produkt/mspa-urban-nest-u-ne021-2-personen/"
              target="_blank"
            >
              <img alt="MSpa Urban Nest U-NE021 aufblasbarer Whirlpool für 2 Personen / für Paare" src="https://m.media-amazon.com/images/I/51IPUW5i2PL.jpg" />
            </a>
          </div>
          <div className="row row-title">
            <a

              href="https://www.whirlpool-king.de/produkt/mspa-urban-nest-u-ne021-2-personen/"
              target="_blank"
            >
              Miweba MSpa Urban Nest U-NE021
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut" src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg" />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
            </div>
            <span>4.7 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img alt="TÜV" src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png" />
              <img alt="GS" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png" />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/CISqSWLItUE");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>oval</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>204 x 70 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Rhino-Tech PVC</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>2 bis 4</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>Ø 930 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.2 - 1.8° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>5 min.</span>
          </div>

          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>138</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="yes">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>
              <abbr
                data-tip="M-ONE System - Ein-Klick Aufbau"
                data-event="click"
                data-event-off="dblclick"
              >
                Selbstaufblasend
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li><a
                data-id="Mspa Urban Nest - Miweba Link"
                href="https://www.miweba.de/garten/outdoor-whirlpool/23591/miweba-mspa-2-personen-whirlpool-urban-nest-u-ne021-mit-rattan-tisch-m-one-ozon-uv-c-desinfektion?sPartner=2131844"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://www.whirlpool-king.de/wp-content/uploads/2020/05/miweba_shop_logo-300x70.png"
                      alt="Miweba Online Shop"
                    />
                  </div>

                  <span>
                    779,99 € <span className="versand">29,90 € Versand</span>
                  </span>
                </div>
              </a></li>
              <li><a
                data-id="Mspa Urban Nest - Amazon Link"
                href="https://www.amazon.de/Miweba-aufblasbarer-Whirlpool-U-NE021-Ozon-UV-C-Desinfektion/dp/B08X224JB2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Miweba+urban+nest&qid=1617127720&sr=8-1&linkCode=ll1&tag=whirlpool-king-split-21&linkId=4eaf35272a52846d161ba11d43d690e9&language=de_DE&ref_=as_li_ss_tl"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon.de Online Shop"
                    />
                  </div>

                  <span>
                    849,99 € <span className="versand">Gratis Versand</span>
                  </span>
                </div>
              </a></li>
            </ol>


          </div>
        </li>

        {/* PLATZ 5 */}

        <li className="column">
          <div className="row row-rank"><span style={{ backgroundColor: '#c9a97a' }} className="badge">Bester Intex Whirlpool</span> 5</div>
          <div className="row row-image">
            <a

              href="https://www.whirlpool-king.de/produkt/intex-purespa-bubble-massage/"
              target="_blank"
            >
              <img alt="Intex PureSpa 77 Whirlpool - Aldi Whirlpool aufblasbar" src="https://images-na.ssl-images-amazon.com/images/I/81PajbA-KoL._AC_SL1500_.jpg" />
            </a>
          </div>
          <div className="row row-title">
            <a

              href="https://www.whirlpool-king.de/produkt/intex-purespa-bubble-massage/"
              target="_blank"
            >
              INTEX PureSpa Bubble Massage 28428
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut" src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg" />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star-half-alt"></i>
            </div>
            <span>4.5 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img
                style={{ visibility: "hidden" }}
                src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png"
              />
              <img
                style={{ visibility: "hidden" }}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png"
              />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/uBg9McXCfqk");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>rund</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>216 x 71 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Vinyl Fiber-Tech</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>6</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>1098 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.5° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>10 min.</span>
          </div>

          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>140</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Selbstaufblasend</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li><a
                data-id="Intex PureSpa - Amazon Link"
                href="https://www.amazon.de/Intex-Whirlpool-Pure-SPA-Massage/dp/B07XTYNC9M?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=28428%2Bintex&qid=1617296074&sr=8-1&th=1&linkCode=ll1&tag=whirlpool-king-split-21&linkId=fffb465b9dcb878a7a71268251a29026&language=de_DE&ref_=as_li_ss_tl"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon.de Online-Shop"
                    />
                  </div>

                  <span>607,87 €</span>
                </div>
              </a></li>
            </ol>

          </div>
        </li>

        {/* PLATZ 6 */}

        <li className="column">
          <div className="row row-rank"><span className="badge" style={{ backgroundColor: '#f4bd24' }}>Amazon Bestseller</span> 6</div>
          <div className="row row-image">
            <a

              href="https://www.whirlpool-king.de/produkt/mspa-tekapo-delight/"
              target="_blank"
            >
              <img alt="MSpa Tekapo Delight" src="https://images-na.ssl-images-amazon.com/images/I/71PAlRKTjsL._AC_SL1200_.jpg" />
            </a>
          </div>
          <div className="row row-title">
            <a
              data-id="MSpa Tekapo - Amazon Link"
              href="https://www.whirlpool-king.de/produkt/mspa-tekapo-delight/"
              target="_blank"
            >
              MSpa Tekapo Delight (4 Personen)
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut" src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg" />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star-half-alt"></i>
            </div>
            <span>4.5 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img alt="TÜV Zertifikat" src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png" />
              <img alt="GS Siegel" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png" />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/lr1JeHglSVU");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>eckig</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>158 x 68 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Rhino-Tech PVC</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>4</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>Ø 930 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.2 - 1.8° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>5 min.</span>
          </div>

          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>108</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="yes">
              <i class="fas fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>
              <abbr
                data-tip="Schnell-Aufblas-Funktion"
                data-event="click"
                data-event-off="dblclick"
              >
                Selbstaufblasend
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li><a
                data-id="MSpa Tekapo - Amazon Link"
                href="https://amzn.to/36plgrD"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon.de Online-Shop"
                    />
                  </div>

                  <span>449,95 €</span>
                </div>
              </a></li>
            </ol>

          </div>
        </li>

        {/* PLATZ 7 */}

        <li className="column">
          <div className="row row-rank">
            {" "}
            <span class="badge">mit Wasserdüsen</span> 7
          </div>
          <div className="row row-image">
            <a
              data-id="MSpa Muse Otium - Miweba Link"
              href="https://www.whirlpool-king.de/produkt/mspa-muse-otium-m-ot061/"
              target="_blank"
            >
              <img alt="MSpa Muse Otium M-OT061 mit 6 Wasserdüsen (Hydrojets)" src="https://www.whirlpool-king.de/wp-content/uploads/2021/02/Miweba_MSpa_Muse_Otium_M-OT061_aufblasbarer_whirlpool_test-768x768.jpg" />
            </a>
          </div>
          <div className="row row-title">
            <a

              href="https://www.whirlpool-king.de/produkt/mspa-muse-otium-m-ot061/"
              target="_blank"
            >
              Miweba MSpa Muse Otium M-OT061
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil 2021: Sehr guter aufblasbarer Whirlpool" src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg" />
          </div>
          <div className="row row-ratings">
            <div className="stars">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star-half-alt"></i>
            </div>
            <span>4.5 / 5 Sterne</span>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img alt="TÜV geprüfter Whirlpool" src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png" />
              <img alt="GS geprüfte Sicherheit" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png" />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/At7dWJOmSuQ");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>rund</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>216 x 71 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>Vinyl Fiber-Tech</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>6</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>1098 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.5° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>10 min.</span>
          </div>

          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>140</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Selbstaufblasend</label>

            <span class="yes">
              <i class="fa fa-check"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li><a
                data-id="Mspa Muse Otium - TLP Link"
                target="_blank"
                rel="nofollow noopener"
                href="https://t.adcell.com/p/click?promoId=146101&slotId=84151&param0=https%3A%2F%2Fwww.trade-line-partner.com%2Fwhirlpools-aufblasbar%2Fmspa_whirlpool_otium_185x185x68cm.html"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://www.whirlpool-king.de/wp-content/uploads/2021/04/Trade-Line-Partner_Shop_Logo-150x23.png"
                      alt="Trade Line Partner Shop"
                    />
                  </div>

                  <span>1099,00 €</span>
                </div>
              </a>
              </li>
              <li> <a
                data-id="Mspa Muse Otium - Miweba Link"
                target="_blank"
                rel="nofollow noopener "
                href="https://www.miweba.de/garten/outdoor-whirlpool/23649/miweba-mspa-whirlpool-2021-muse-otium-m-ot061-hydro-massage-m-one-ozon-uv-c-reinigung-6-personen?sPartner=2131844"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://www.whirlpool-king.de/wp-content/uploads/2020/05/miweba_shop_logo-300x70.png"
                      alt="Miweba.de Online-Shop MSpa Partner"
                    />
                  </div>

                  <span>1179,99 €</span>
                </div>
              </a>
              </li>
              <li><a
                data-id="MSpa Muse Otium - Amazon Link"
                href="https://www.amazon.de/Miweba-aufblasbarer-Whirlpool-Otium-M-OT061/dp/B08YKDLF42/?tag=whirlpool-king-split-21"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon.de Online-Shop"
                    />
                  </div>

                  <span>1299,99 €</span>
                </div>
              </a></li>
            </ol>
          </div>
        </li>

        {/* PLATZ 8 */}

        <li className="column">
          <div className="row row-rank">8</div>
          <div className="row row-image">
            <a

              href="https://www.whirlpool-king.de/produkt/bestway-lay-z-spa-miami-airjet/"
              target="_blank"
            >
              <img alt="Bestway Lay-Z-Spa Miami AirJet" src="https://m.media-amazon.com/images/I/31ZX7SGC4xL.jpg" />
            </a>
          </div>
          <div className="row row-title">
            <a

              href="https://www.whirlpool-king.de/produkt/bestway-lay-z-spa-miami-airjet/"
              target="_blank"
            >
              Bestway Lay-Z-Spa Miami AirJet
            </a>
          </div>
          <div className="row row-testsiegel">
            <img alt="Test Urteil: Sehr Gut" src="https://www.whirlpool-king.de/wp-content/uploads/2019/12/TESTSIEGEL_Whirlpool_aufblasbar_sehr_gut_Testnote.jpg" />
          </div>
          <div className="row row-ratings">
            <a
              data-id="Bestway Lay-Z-Spa - Amazon Bewertungen"
              href="https://amzn.to/2PYKzMc" rel="nofollow"
              target="_blank"
            >
              <div className="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
              </div>

              <span>4.4 / 5 Sterne</span>
            </a>
          </div>
          <div className="row row-spec certs">
            <label>Zertifikate </label>
            <div className="certs-imgs">
              <img
                style={{ visibility: "hidden" }}
                src="https://cdn.vergleich.org/img/sites/2/TuevSeal-e1597319042875.png"
              />
              <img
                style={{ visibility: "hidden" }}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/GS-Zeichen.svg/1200px-GS-Zeichen.svg.png"
              />
            </div>
          </div>
          <div className="row row-spec yt">
            <label>Youtube Video </label>
            <i
              onClick={() => {
                setModalStatus(true);
                setModalURL("https://www.youtube.com/embed/7EGYQ5NPnMk");
              }}
              class="fab fa-youtube"
            ></i>
          </div>
          <div className="row row-spec">
            <label>Form </label>
            <span>rund</span>
          </div>
          <div className="row row-spec">
            <label>Maße </label>
            <span>180 x 66 cm</span>
          </div>
          <div className="row row-spec">
            <label>Material </label>
            <span>TriTech PVC</span>
          </div>
          <div className="row row-spec">
            <label>Anzahl Personen </label>
            <span>2 bis 4</span>
          </div>
          <div className="row row-spec">
            <label>Füllmenge </label>
            <span>1098 Liter</span>
          </div>
          <div className="row row-spec">
            <label>Max. Wassertemperatur </label>
            <span>40° C</span>
          </div>
          <div className="row row-spec">
            <label>Heizleistung pro Stunde </label>
            <span>1.5° C</span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Dauer </label>
            <span>10 min.</span>
          </div>

          <div className="row row-spec">
            <label>Luftdüsen Anzahl </label>
            <span>82</span>
          </div>
          <div className="row row-spec">
            <label>Luftdüsen einstellbar </label>
            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>
              mit{" "}
              <abbr
                data-tip="Für mehr Entspannung, kostenintensiver"
                data-event="click"
                data-event-off="dblclick"
              >
                Wasserdüsen
              </abbr>
              <ReactTooltip globalEventOff="click" />
            </label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>mit Fernbedienung</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Ozongenerator</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>UV-C Desinfektion</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>

          <div className="row row-spec">
            <label>Winterfest</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>LED Beleuchtung</label>

            <span class="no">
              <i class="fas fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Selbstaufblasend</label>

            <span class="no">
              <i class="fa fa-times"></i>
            </span>
          </div>
          <div className="row row-spec">
            <label>Aufbau Schwierigkeit </label>
            <span>sehr leicht</span>
          </div>
          <div className="row row-buy">
            <label>Günstigster Anbieter</label>
            <ol>
              <li><a
                data-id="Bestway Lay-Z-Spa - Amazon Link"
                href="https://amzn.to/3mAP9wB"
                target="_blank" rel="nofollow"
              >
                <div className="btn-buy">
                  <div className="shop-img">
                    <img
                      src="https://cdn.vergleich.org/img/sites/2/logo-amazon.png"
                      alt="Amazon.de Logo"
                    />
                  </div>

                  <span>579,99 €</span>
                </div>
              </a></li>
            </ol>


          </div>
        </li>
      </ol>
    </div>
  );
};

export default TableEmbed;
